<template>
  <div>
    <ca-header
      heading="Garantie-Vorsorge-Check Formular herunterladen"
      :loading="loading"
    />
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-card>
            <template #header>
              <h4 class="mb-0">
                <i class="fa fa-download" /> Garantie-Vorsorge-Check Formular herunterladen
              </h4>
            </template>
            <template #body>
              <button
                v-if="!client"
                class="btn-primary"
                :disabled="!client"
              >
                <i class="far fa-spinner fa-circle-notch" />
              </button>
              <SignedDownloadLink
                v-if="client && client.gvcFile"
                :file="client.gvcFile"
              >
                <template #default="{link}">
                  <a
                    class="btn btn-primary text-white"
                    :href="link"
                    :download="client.gvcFile.name"
                  >Herunterladen</a>
                </template>
              </SignedDownloadLink>
            </template>
          </ca-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import CaCard from '@/components/Card'
import SignedDownloadLink from '@/components/SignedDownloadLink'
export default {
  name: 'GvcPdf',
  components: {
    CaCard,
    CaHeader,
    SignedDownloadLink
  },
  data () {
    return {
      loading: false,
      loadingError: null,
      client: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
      } catch (error) {
        this.loadingError = error
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
